// import node_modules
import React from 'react';

// import components
import Layout from '../components/Layout';
import NotFound from '../components/NotFound';

// import content
import content from '../content/general.json';

// declare custom component
const NotFoundPage = () => (
  <Layout
    description={content.notfound.description}
    image={content.notfound.image}
    pathname="/404/"
    title={content.notfound.title}
  >
    <NotFound
      backgroundImage={content.notfound.image}
      ctaLabel={content.notfound.cta.label}
      ctaLink={content.notfound.cta.link}
      description={content.notfound.description}
      title={content.notfound.title}
    />
  </Layout>
);

export default NotFoundPage;
