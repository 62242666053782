// import node_modules
import styled from 'styled-components';
import { Link } from 'gatsby';

// import styles
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import fonts, { mainHeadlineStyle } from '../../styles/fonts';
import shadows from '../../styles/shadows';
import transitions from '../../styles/transitions';

// define styles
export const Section = styled.section`
  align-content: center;
  align-items: center;
  background-color: ${colors.white};
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: calc(100vh - 70px);
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    height: 100%;
    min-height: 70vh;
  }
`;

export const SectionContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 720px;
  padding: 3rem 1rem;
`;

export const SectionTitle = styled.h1`
  ${mainHeadlineStyle}

  font-size: 48px;
  margin: 0 0 1rem;
  text-align: center;
`;

export const SectionButton = styled(Link)`
  background-color: ${colors.link};
  border: none;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.families.default};
  font-size: 20px;
  font-weight: ${fonts.weights.bold};
  justify-content: center;
  letter-spacing: ${fonts.letterSpacings.labels};
  line-height: ${fonts.lineHeights.labels};
  outline: 0;
  padding: 1.2rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ${transitions.easeOut};

  &:hover {
    box-shadow: ${shadows.default};
  }
`;
